/// <reference types="vite/client" />
import { Uuid } from "@/classes/Uuid";
import posthog from "posthog-js";
(() => {
  posthog.init("phc_tOjDpc98PeBEscPhq6rrcsXHmEVjrtM8M7rQqQ7T2Kb", {
    api_host: "https://d19evrx0vcvxfw.cloudfront.net",
    ui_host: "https://us.posthog.com",
    disable_session_recording: true,
    loaded: function (posthog) {
      window.posthog = posthog;
      Uuid.setUuid(posthog.get_distinct_id());
    },
  });

  const isDev = import.meta.env.DEV;

  if (isDev) {
    document.addEventListener(
      "click",
      /**
       * @param {MouseEvent} event
       */
      function (event) {
        /** @type {HTMLAnchorElement | null} */
        const target = event.target instanceof Element ? event.target.closest("a") : null;
        if (!(target instanceof HTMLAnchorElement)) return;
        const url = new URL(target.href);
        const pathname = url.pathname;
        if (pathname.startsWith("/pages")) {
          return;
        }
        event.preventDefault();
        const newPathname = `/pages${pathname.startsWith("/") ? "" : "/"}${pathname}`;
        const newUrl = `${newPathname}${url.search}${url.hash}`;
        if (target.getAttribute("target") === "_blank") {
          window.open(newUrl, "_blank");
        } else {
          window.location.href = newUrl;
        }
      },
      true,
    );
  }

  /** @type {any} */
  const convert = window.convert;

  try {
    getExperiments();
    function getExperiments() {
      Object.entries(convert?.currentData?.experiments || {}).map(([key, value]) => {
        return storeExperiments(value.variation_id, key);
      });
    }
    /**
     * @param {string | number} variant
     * @param {string} experiment
     */
    function storeExperiments(variant, experiment) {
      /**
       * @typedef {Object} Experiment
       * @property {string} id
       * @property {string} date
       * @property {string} experiment
       * @property {string|number} variant
       * @property {string} dateLast
       */

      /** @type {Experiment[]} */
      let experiments = JSON.parse(localStorage.getItem("thr_convert_experiments") || "[]");
      let key = `${experiment}:${variant}`;
      let now = new Date().toISOString();
      let ex = experiments.find(
        /** @param {Experiment} e */
        (e) => e.id === key,
      );
      if (ex) {
        ex.dateLast = now;
      } else {
        experiments.push({ id: key, date: now, experiment: experiment, variant: variant, dateLast: now });
      }
      localStorage.setItem("thr_convert_experiments", JSON.stringify(experiments));
    }
  } catch (e) {
    console.error(e);
  }

  const stagingQuizId = "671e7356-4064-4fdc-b5b4-8611ea070472";
  let search = new URLSearchParams(window.location.search);
  let quiz = document.querySelector("qubl-instance,qubl-preview");

  if (search.has("test") && quiz) {
    quiz.outerHTML = quiz.outerHTML.replace("qubl-instance", "qubl-preview");
    quiz = document.querySelector("qubl-preview");
  }

  if (quiz) {
    let quizId = quiz.getAttribute("quiz-id");
    if (search.has("quiz")) {
      if (search.get("quiz") === "test") {
        quizId = stagingQuizId;
      } else {
        quizId = search.get("quiz");
      }
    }
    if (quizId) {
      quiz.setAttribute("quiz-id", quizId);
    }
  }

  /** @type {string} */
  // @ts-ignore
  const bucket = isDev ? window.__bucket__ : __bucket__;

  /** @type {string} */
  // @ts-ignore
  const url = isDev ? window.__url__ : __url__;

  /** @type {string} */
  // @ts-ignore
  const urlStaging = isDev ? window.__url_staging__ : __url_staging__;

  /** @type {string} */
  // @ts-ignore
  const cf_alias = isDev ? window.__cf_alias__ : __cf_alias__;

  const baseURLs = {
    default: "https://" + bucket + ".s3.amazonaws.com/staging/",
    ["www." + url]: "https://" + cf_alias + "/prod/",
    [url]: "https://" + cf_alias + "/prod/",
    [urlStaging]: "https://" + bucket + ".s3.amazonaws.com/staging/",
  };

  const entryPoints = [
    { url: "/intake-form", script: "intake-form" },
    { url: "/appointment/loading", script: "loading-appointment-d2c" },
    { url: "/loading", script: "loading-testimonial-test" },
    { url: "/", script: "home", match: "exact" },
    { url: "/quiz-self-serve", script: "self-serve-quiz" },
    { url: "/quiz-vitamins-nutrients", script: "quiz-vitamins" },
    { url: "/quiz-insurance", script: "quiz" },
    { url: "/quiz-business", script: "quiz-business" },
    { url: "/appointment/quiz", script: "quiz-d2c" },
    { url: "/quiz", script: "quiz" },
    { url: "/sleep-quiz-results", script: "results-engagement" },
    { url: "/caffeine-quiz-results", script: "results-engagement" },
    { url: "/breakfast-quiz-results", script: "results-engagement" },
    { url: "/energy-quiz-results", script: "results-engagement" },
    { url: "/sleep-quiz", script: "quiz-engagement" },
    { url: "/caffeine-quiz", script: "quiz-engagement" },
    { url: "/breakfast-quiz", script: "quiz-engagement" },
    { url: "/energy-quiz", script: "quiz-engagement" },
    { url: "/self-serve-loading-test", script: "selfServeLoadingTest" },
    { url: "/self-serve-checkout", script: "selfServeCheckout" },
    { url: "/self-serve-appointment-v2", script: "appointment-scheduler-v2", app: true },
    { url: "/self-serve-appointment", script: "appointment-scheduler", app: true },
    { url: "/dietitians/", script: "appointment-scheduler", app: true },
    // { url: "/self-serve-results", script: "dietitian-results", app: true },
    { url: "/self-serve-plan", script: "dietitian-plans", app: true },
    { url: "/results", script: "resultsNewLeadFlow" },
    { url: "/business-schedule-call", script: "business-scheduling" },
    { url: "/next-steps", script: "schedule-matching-call" },
    { url: "/not-available-in-your-state", script: "state-unsupported" },
    { url: "/schedule-matching-call", script: "schedule-matching-call" },
    { url: "/give-50-get-50", script: "referral-advocate" },
    { url: "/refer-a-friend", script: "referral-friend" },
    { url: "/medical-referrals", script: "referral-friend" },
    { url: "/booking-confirmation", script: "booking-confirmation" },
    { url: "/dietitian-jobs", script: "job-posts", app: true },
    { url: "/programs-thrivewell", script: "programs" },
    { url: "/programs-virtual-call", script: "programs" },
    { url: "", script: "all" },
  ];

  let baseURL;

  if (baseURLs[location.hostname] !== undefined) {
    baseURL = baseURLs[location.hostname];
  } else {
    baseURL = baseURLs["default"];
  }

  const entryPoint = entryPoints.find((entryPoint) => {
    entryPoint.match ??= "prefix";
    switch (entryPoint.match) {
      case "exact":
        if (isDev) {
          return window.location.pathname === "/pages" + entryPoint.url;
        } else {
          return window.location.pathname === entryPoint.url;
        }
      case "prefix":
      default:
        if (isDev) {
          return window.location.pathname.startsWith("/pages" + entryPoint.url);
        } else {
          return window.location.pathname.startsWith(entryPoint.url);
        }
    }
  });
  console.debug({ baseURL, entryPoint, location: window.location.pathname, hostname: location.hostname });

  if (entryPoint) {
    if (entryPoint.app) {
      if (process.env.NODE_ENV === "development") {
        import(`./apps/${entryPoint.script}/main.ts`);
      } else {
        import(/* @vite-ignore */ `${baseURL}js/${entryPoint.script}.js`);
      }
    } else {
      if (process.env.NODE_ENV === "development") {
        import(`./module-${entryPoint.script}.ts`);
      } else {
        import(/* @vite-ignore */ `${baseURL}js/module-${entryPoint.script}.js`);
      }
    }
  }
})();
